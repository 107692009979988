import { Toaster } from 'react-hot-toast'
import { BviCustom } from './components/module/bvi-custom'
import { ThemeContextType } from './common/types'
import { createContext, useEffect, useState } from 'react'
import { ConfigProvider, theme as TH } from 'antd'
import AppRoutes from './routes/AppRoutes'
import i18next from 'i18next'
import ruRU from 'antd/es/locale/ru_RU'
import './options.scss'
import './App.scss'

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined
)

function App() {
  const [theme, setTheme] = useState<string>('light')
  const [options, setOptions] = useState<{
    theme:
      | null
      | 'whiteblack'
      | 'blackwhite'
      | 'blueblack'
      | 'yellowblack'
      | 'browngreen'
    fontSize: null | 'f14' | 'f16' | 'f17' | 'f18' | 'f19'
    image: null | 'grayscale' | 'off'
    spacing: null | 'middle' | 'big'
    lineHeight: null | 'lh40' | 'lh30'
    fontFamily: null | 'with'
  }>({
    theme: null,
    fontSize: null,
    image: null,
    spacing: null,
    lineHeight: null,
    fontFamily: null,
  })

  useEffect(() => {
    document.documentElement.lang = i18next.language
  }, [i18next.language])
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ConfigProvider
        locale={ruRU}
        theme={{
          algorithm: theme === 'dark' ? TH.darkAlgorithm : undefined,
        }}
      >
        <Toaster position='top-center' reverseOrder={true} />
        <div className={theme === 'dark' ? 'App dark' : 'App'}>
          <BviCustom settings={options} setSettings={setOptions} />
          <div
            className={`
          options ${options.fontSize !== null && options.fontSize} ${
              options.spacing !== null && options.spacing
            } ${options.fontFamily ? 'with' : ''} ${
              options.image !== null && options.image
            } ${options.lineHeight !== null && options.lineHeight} ${
              options.theme !== null && options.theme
            }
          `}
            style={{ width: '100%', height: '100%' }}
          >
            <AppRoutes />
          </div>
        </div>
      </ConfigProvider>
    </ThemeContext.Provider>
  )
}

export default App
