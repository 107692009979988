import axios, { AxiosInstance } from 'axios'
import { _KEY_AUTH } from '../common/variables/variables'
import { getCookie } from '../components/function'

const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://bankrotstvo.gov.kg/api',
  timeout: 5000,
})

axiosInstance.interceptors.request.use((config) => {
  const cookieDataString = getCookie(_KEY_AUTH)
  const convertObj = cookieDataString ? JSON.parse(cookieDataString) : {}

  if (convertObj.token) {
    config.headers.Authorization = `Bearer ${convertObj.token}`
  } else {
    config.headers.Authorization = ''
  }

  return config
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
