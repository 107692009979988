import { initReactI18next } from 'react-i18next'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './app/store'
import i18n from 'i18next'
import App from './App'
import kg from './localize/kg.json'
import ru from './localize/ru.json'
import './index.css'

const container = document.getElementById('bank')!
const root = createRoot(container)

i18n.use(initReactI18next).init({
  resources: {
    kg: {
      translation: kg,
    },
    ru: {
      translation: ru,
    },
  },
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false,
  },
})

root.render(
  // <StrictMode>
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
  // </StrictMode>
)
