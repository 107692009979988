export const _KEY_AUTH = 'USER_BANKRUPTCY_DATA'

export const BankTypeData = ['PROCESS', 'OTHER', 'REFRESH', 'END']

export const TypeOfProcedureData = [
  'LIQUIDATION',
  'RESTRUCTURING',
  'INDIVIDUAL_ENTREPRENEUR',
  'NO',
]

export const BankSelect = [
  {
    label: 'Юридическое лицо',
    value: 'LEGAL_PERSON',
  },
  {
    label: 'Индивидуальный предприниматель',
    value: 'NATURAL_PERSON',
  },
]

export const InitiationAction = [
  {
    label: 'Инициирован',
    value: 'INITIATED',
  },
  {
    label: 'Не инициирован',
    value: 'NOTINITIATED',
  },
  {
    label: 'Возвращен',
    value: 'RETURNED',
  },
]

export const AuctionDataEvent = [
  {
    label: 'Аукцион',
    value: 'ACTION',
  },
  {
    label: 'Конкурс',
    value: 'CONCURS',
  },
]

export const BankSelectAllInteger = [
  {
    label: 'Юридическое лицо',
    value: 0,
  },
  {
    label: 'Индивидуальный предприниматель',
    value: 1,
  },
  {
    label: 'Физическое лицо',
    value: 2,
  },
]
export const BankSelectAll = [
  {
    label: 'Юридическое лицо',
    value: 'LEGAL_PERSON',
  },
  {
    label: 'Индивидуальный предприниматель',
    value: 'NATURAL_PERSON',
  },
  {
    label: 'Физическое лицо',
    value: 'PHYSICAL_PERSON',
  },
]

export const TypeOfProcedureMethod = [
  {
    value: 0,
    label: 'Ликвидация',
  },
  {
    value: 1,
    label: 'Реструктуризация',
  },
  {
    value: 2,
    label: 'Банкротство индивидуального предпренимателя',
  },
  {
    value: 3,
    label: 'Нет',
  },
]

export const HTMLTypeData = [
  /**
   * Руководство
   */
  'LEADERSHIP',
  /**
   * Структура
   */
  'STRUCTURE',
  /**
   * Положение
   */
  'POSITION',
  /**
   * Вакансии
   */
  'JOB',
  /**
   * История департамента
   */
  'HISTORY',
  /**
   * КОНТАКТЫ
   */
  'CONTACTS',
  /**
   * Состав лицензионной комиссии
   */
  'LICENSING_COMMISSION',
]

export const QuarterData = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
]

export const TypeMonth = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

export const WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']

export const POSITION_TYPE = [
  {
    value: 'SPEC_ADMIN',
    label: 'Спец Администратор',
  },
  {
    value: 'KURATOR',
    label: 'Куратор',
  },
]

export const Oblastlabels: { [key: string]: string } = {
  bishkek: 'г.Бишкек',
  chui: 'Чуй',
  issykKul: 'Ыссык-Куль',
  talas: 'Талас',
  naryn: 'Нарын',
  jalalAbad: 'Джалал-Абад',
  osh: 'Ош',
  batken: 'Баткен',
}

export const USER_STATUS = [
  {
    label: 'Активный',
    value: 'ACTIVE',
  },
  {
    label: 'Заблокирован',
    value: 'BAN',
  },
  {
    label: 'Не подтвержден',
    value: 'NEW',
  },
]

export const ROlE_DATA = [
  {
    label: 'Пользователь',
    value: 'ROLE_USER',
  },
  {
    label: 'Администратор',
    value: 'ROLE_ADMIN',
  },
  {
    label: 'Модератор',
    value: 'ROLE_MODERATOR',
  },
  {
    label: 'Юрист',
    value: 'ROLE_LENSER',
  },
  {
    label: 'Менеджер',
    value: 'ROLE_MANAGER',
  },
]

export const LEGAL_STATUS = [
  {
    label: 'Действующий',
    value: true,
  },
  {
    label: 'Не действующий',
    value: false,
  },
]

export const LANG_UPPER = [
  {
    label: 'рус',
    value: 'RU',
  },
  {
    label: 'кыр',
    value: 'KG',
  },
]

export const LANG_UPPER_NUM = [
  {
    label: 'рус',
    value: 0,
  },
  {
    label: 'кыр',
    value: 1,
  },
]

export const ARTICLE_TYPE = [
  {
    label: 'Законодательство КР, Указы Президента, Постановления ЖК КР',
    value: 'LAW',
  },
  {
    label: 'Постановления Правительства, Распоряжения',
    value: 'REGULATION',
  },
  {
    label: 'Положения, правила, инструкции',
    value: 'RULES',
  },
  {
    label: 'Документация по лицензированию',
    value: 'LICENSING_DOCUMENTATION',
  },
  {
    label: 'Документация по процедуре банкротства',
    value: 'BANKRUPTCY_DOCUMENTATION',
  },
  {
    label: 'Документация по инициированию банкротства',
    value: 'BANKRUPTCY_INITIATION_DOCUMENTATION',
  },
  {
    label: 'Ваканции',
    value: 'JOB',
  },
  {
    label: 'Информация для кредиторов',
    value: 'INFORMATION_FOR_CREDITORS',
  },
]

export const STATUS_DATA = [
  {
    label: 'Активный',
    value: 'ACTIVE',
  },
  {
    label: 'Заблокирован',
    value: 'BLOCK',
  },
  {
    label: 'Не подтвержден',
    value: 'NEW',
  },
]

export const ACTIVE_DATA = [
  {
    label: 'Активный',
    value: true,
  },
  {
    label: 'Не активный',
    value: false,
  },
]

export const ROLE_DATA = [
  {
    label: 'Контент менеджер',
    value: 'ROLE_MANAGER',
  },
  {
    label: 'Администратор',
    value: 'ROLE_ADMIN',
  },
  {
    label: 'Модератор',
    value: 'ROLE_MODERATOR',
  },
  {
    label: 'Юрист',
    value: 'ROLE_LENSER',
  },
]

export const TYPE_DATA = [
  {
    value: 'POSITION',
    label: 'Положение',
  },
  {
    value: 'STRUCTURE',
    label: 'Структура',
  },
  {
    value: 'HISTORY',
    label: 'История департамента',
  },
  {
    value: 'LEADERSHIP',
    label: 'Руководство',
  },
  {
    value: 'CONTACTS',
    label: 'Контакты',
  },
]
